<template>
  <div class="text-center section position-relative">
    <ModuleLocked v-if="!calendarModule" />
    <div v-else-if="isLoading" class="overlay-loading">
      <loading />
    </div>
    <Calendar
      v-if="calendarModule"
      class="custom-calendar max-w-full"
      :masks="masks"
      ref="calendar"
      :locale="$i18n.locale"
      :first-day-of-week="2"
      @transition-start="GET_ITEMS"
      :attributes="attributes"
      disable-page-swipe
      is-expanded
    >
      <template v-slot:day-content="{ day, attributes }">
        <div
          @dblclick="createEvent(day)"
          class="d-flex flex-col h-100 z-10 overflow-hidden"
        >
          <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
          <div class="scroll-calendar">
            <div
              v-for="attr in attributes"
              :key="attr.key"
              @click="showModal(attr.customData)"
              class="text-xs w-100 rounded-sm p-1 mt-0 mb-1"
              :class="attr.customData.class"
            >
              <div>{{ formatter(attr.customData.date) }}</div>
              <div>
                <small>{{ attr.customData.userInfo.fullname }}</small>
              </div>
              <small> {{ attr.customData.title }}</small>
            </div>
          </div>
        </div>
      </template>
    </Calendar>
    <Modal v-if="item" :title="item.title" v-model="showDetail" hide-footer>
      <ItemDetail :item="item" />
    </Modal>
    <NewAddEvent
      v-if="selectedDate"
      @selected="SET_ACTIVE_EVENT"
      :product="item"
      :date="selectedDate"
    />
    <NewEventDates
      :product="activeEvent"
      @refresh="GET_ITEMS()"
      :date="selectedDate"
    />
  </div>
</template>

<script>
import Calendar from "v-calendar/src/components/Calendar.vue";
import Modal from "../Shared/Modal/index.vue";
import { formatter } from "gurhan/helpers/Date";
import ItemDetail from "../ClosestEvents/ItemDetail.vue";
import NewAddEvent from "./NewAddEvent.vue";
import NewEventDates from "../Product/NewEventDates.vue";
import Modules from "../../mixins/Modules.js";
import ModuleLocked from "../Packages/ModuleLocked.vue";
export default {
  mixins: [Modules],
  components: {
    Calendar,
    Modal,
    NewAddEvent,
    NewEventDates,
    ItemDetail,
    ModuleLocked,
  },
  props: {
    items: {
      default: () => [],
    },
  },
  computed: {
    getActiveUser() {
      return this.$store.state.User.activeUser;
    },
  },
  data() {
    return {
      masks: {
        weekdays: "WWW",
      },
      attributes: [],
      isLoading: true,
      colors: ["danger", "warning", "primary", "info", "secondary"],
      item: null,
      showDetail: false,
      selectedDate: null,
      activeEvent: null,
      count: 0,
    };
  },
  methods: {
    formatter,
    SET_ACTIVE_EVENT(event) {
      this.activeEvent = event;
      this.$router.replace({
        query: { ...this.$route.query, modal: "add-eventDate" },
      });
    },
    createEvent(day) {
      this.selectedDate = day.id;
      this.$router.replace({
        query: { ...this.$route.query, event: "add-new-event" },
      });
    },
    showModal(item) {
      this.item = item;
      this.showDetail = true;
    },
    FIND_REF(id) {
      console.log(id);
    },
    CHECK_QUERY() {
      if (process.server) return;
      const event = this.$route.query.event;
      if (!isNaN(Number(event))) this.FIND_REF(Number(event));
    },
    async GET_ITEMS() {
      let dateString = "";
      if (this.$refs.calendar) {
        const dates = this.$refs.calendar.getPageDays();
        const firstDate = dates[0];
        const lastDate = dates[dates.length - 1];
        dateString = `&startDate=${firstDate.id}&endDate=${lastDate.id}`;
      }
      this.isLoading = true;
      const userFilter =
        this.getActiveUser.roleId === 3
          ? `&userId=${this.getActiveUser.id}`
          : "";
      let link = `UserEvents/ExternalEvents?currency=${this.$i18n.locale}&limit=1000&page=1${userFilter}${dateString}`;
      const response = await this.$api.get(link);
      if (response.message === "OK") {
        this.attributes = response.data.items.map((item, i) => ({
          key: i,
          customData: {
            ...item,
            class: `bg-${
              this.colors[Math.floor(Math.random() * 5)]
            } text-white`,
          },
          popover: {
            label: "test",
          },
          dates: new Date(item.date + "+03:00"),
        }));
        this.count = response.data.count;
        setTimeout(() => {
          this.CHECK_QUERY();
        }, 250);
      } else this.items = [];
      this.isLoading = false;
    },
  },
  mounted() {
    setTimeout(() => {
      this.GET_ITEMS();
    }, 250);
  },
};
</script>

<style>
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}
.custom-calendar.vc-container {
  border-radius: 0;
  width: 100%;
}
.custom-calendar.vc-container .vc-header {
  background-color: #f1f5f8;
  padding: 10px 0;
}
.custom-calendar.vc-container .vc-weeks {
  padding: 0;
}
.custom-calendar.vc-container .vc-weekday {
  background-color: #f8fafc;
  border-bottom: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
  padding: 5px 0;
}
.custom-calendar.vc-container .vc-day {
  padding: 0 5px 3px 5px;
  text-align: left;
  height: 180px;
  min-width: 90px;
  background-color: white;
}
.custom-calendar.vc-container .is-today {
  background: rgb(255, 203, 203);
}
.custom-calendar.vc-container .vc-day.weekday-1,
.custom-calendar.vc-container .vc-day.weekday-7 {
  background-color: #eff8ff;
}
.custom-calendar.vc-container .vc-day:not(.on-bottom) {
  border-bottom: 1px solid #b8c2cc;
}
.custom-calendar.vc-container .vc-day:not(.on-bottom).weekday-1 {
  border-bottom: 1px solid #b8c2cc;
}
.custom-calendar.vc-container .vc-day:not(.on-right) {
  border-right: 1px solid #b8c2cc;
}
.custom-calendar.vc-container .vc-day-dots {
  margin-bottom: 5px;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-auto {
  overflow-x: auto;
}
.scroll-calendar {
  padding-left: 10px;
  height: 100%;
  font-size: 12px;
  cursor: pointer;
  width: 100%;
  overflow: auto;
}
.overlay-loading {
  position: absolute;
  z-index: 5;
  background: #dedede51;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
